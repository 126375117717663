<template>
<el-input :type="passw" :placeholder="placeholder" v-model="values" @input="onChange" ><!-- auto-complete="new-password" -->
    <i
      slot="prefix"
      :class="icon"
      @mouseover="showPass(true)"
      @mouseleave="showPass(false)"
    ></i>
  </el-input>

</template>

<script>
export default {
  props: {
    passWord: {
      type: String,
      default: ""
    },
    placeholder:{
        type:String,
        default:"请输入密码"
    }
  },
  data() {
    return {
      passw: "password",
      icon: "el-icon-view",
      values:''
    };
  },
  watch:{
      passWord(e){
          this.values=e
      }
  },
  methods: {
    //密码的隐藏和显示
    showPass(val, id = 0) {
      let passw = "password";
      let icon = "el-icon-view";
      if (val) {
        passw = "text";
        icon = "el-icon-loading";
      }
      if (id == 0) {
        this.passw = passw;
        this.icon = icon;
      } else {
        this.passw1 = passw;
        this.icon1 = icon;
      }
    },
    onChange(val){
        this.$emit("update:passWord", val)
    }
  },
};
</script>